import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  // OrderedList,
  UnorderedList,
  Button,
  IconButton,
  useClipboard,
  Link,
} from '@chakra-ui/react'
// import copy from 'copy-to-clipboard'
import { CopyIcon, CheckIcon } from '@chakra-ui/icons'
import { graphql } from 'gatsby'
import BaseLayout from '../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../components/ContentWithStyles/TransformToChakra'
import SEO from '../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Contraseña')
  const generatePassword = () => {
    const abcUppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const abcLowercase = 'abcdefghijklmnopqrstuvwxyz'
    const numbers = '0123456789'
    const specialCharacters = '#$€%&()[]{}*/\\-+:;.,=@¡!¿?_^~'
    const pwdChars =
      abcUppercase.repeat(3) +
      abcLowercase.repeat(3) +
      numbers.repeat(4) +
      specialCharacters
    const pwdLen = 16
    return Array(pwdLen)
      .fill(pwdChars)
      .map(x => x[Math.floor(Math.random() * x.length)])
      .join('')
    // return Math.random().toString(36).substr(2, 24)
  }
  const { hasCopied, onCopy } = useClipboard(word)
  // const selectRandomItem = someList =>
  //   someList[Math.floor(Math.random() * someList.length)]

  useEffect(() => {
    setWord(generatePassword())
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de contraseñas seguras y aleatorias"
        titleSeo="Generador de contraseñas seguras y aleatorias"
        description="Herramienta para generar claves seguras y contraseñas robustas para mantener tus cuentas seguras."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        // breadcrumbs={[{ name: 'Vegetales', url: '/vegetales/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
            <IconButton
              px={6}
              colorScheme="orange"
              aria-label="Copiar contraseñas al portapapeles"
              onClick={onCopy}
              ml={2}
              icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
            />
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador contraseñas seguras y aleatorias
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => setWord(generatePassword())}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>
          Generador de contrase&ntilde;as seguras: claves fuertes y aleatorias
        </Heading1>
        <Paragraph>
          En la actualidad, seg&uacute;n algunos estudios una persona promedio
          tiene{' '}
          <Link
            color="orange.400"
            href="https://securitybrief.co.nz/story/average-person-has-100-passwords-study"
            target="_blank"
            rel="noreferrer noopener"
          >
            alrededor de 100 contrase&ntilde;as en uso
          </Link>
          , con algunas alternativas se&ntilde;alando que{' '}
          <Link
            color="orange.400"
            href="https://www.newswire.com/news/new-research-most-people-have-70-80-passwords-21103705"
            target="_blank"
            rel="noreferrer noopener"
          >
            lo m&aacute;s com&uacute;n es tener entre 70 y 80 contrase&ntilde;as
          </Link>{' '}
          a recordar/utilizar para diferentes cuentas en sitios web y
          aplicaciones m&oacute;viles. Es por esto que resulta muy tentador caer
          en{' '}
          <Link
            color="orange.400"
            href="https://digitalguardian.com/blog/uncovering-password-habits-are-users-password-security-habits-improving-infographic"
            target="_blank"
            rel="noreferrer noopener"
          >
            las malas pr&aacute;cticas m&aacute;s comunes
          </Link>{' '}
          al momento de escoger una contrase&ntilde;a segura y terminar
          repitiendo las mismas una y otra vez, o siguiendo un patr&oacute;n muy
          f&aacute;cil de desvelar.
        </Paragraph>
        <Paragraph>
          Ante el riesgo de comprometer nuestros datos sensibles por usar
          contrase&ntilde;as d&eacute;biles, f&aacute;ciles de descifrar o
          repetidas, dos herramientas resultan absolutamente b&aacute;sicas para
          protegernos: un buen generador de contrase&ntilde;as seguras y
          aleatorias, y un robusto gestor de contrase&ntilde;as que nos ayude a
          llevar el registro de todas nuestras cuentas con contrase&ntilde;as
          &uacute;nicas y originales, sin repetir ni caer en patrones comunes.
        </Paragraph>
        <Paragraph>
          Es por eso que ah&iacute; tenemos a nuestra disposici&oacute;n un
          confiable generador de contrase&ntilde;as online que nos
          permitir&aacute; no solo personalizar las caracter&iacute;sticas de la
          misma y copiarla al portapapeles f&aacute;cilmente, sino que
          adem&aacute;s nos asegurar&aacute; que nuestra contrase&ntilde;a es
          realmente aleatoria y compleja para que, ante un ataque de fuerza
          bruta o la filtraci&oacute;n de alguna de nuestras contrase&ntilde;as,
          el resto de nuestras cuentas no se vean comprometidas y nos ahorremos
          as&iacute; muchos dolores de cabeza.
        </Paragraph>
        <Heading2>
          C&oacute;mo generar claves seguras y aleatorias: mejores
          pr&aacute;cticas
        </Heading2>
        <Paragraph>
          Esta herramienta de generaci&oacute;n de claves online cuenta con una
          configuraci&oacute;n por defecto que te asegurar&aacute; que la
          contrase&ntilde;a generada es realmente aleatoria y lo suficientemente
          compleja como para no poder ser descifrada mediante fuerza bruta, pero
          siempre es bueno conocer cu&aacute;les son los h&aacute;bitos
          recomendados al elegir una nueva clave de seguridad que sea{' '}
          <Link
            color="orange.400"
            href="https://www.it.ucsb.edu/secure-compute-research-environment-user-guide/password-best-practices"
            target="_blank"
            rel="noreferrer noopener"
          >
            confiable y robusta
          </Link>
          .
        </Paragraph>
        <Heading3>
          Evitar usar contrase&ntilde;as ya conocidas o compartidas
        </Heading3>
        <Paragraph>
          Comenzamos con un consejo realmente b&aacute;sico y de puro sentido
          com&uacute;n, pero que a&uacute;n a d&iacute;a de hoy sigue siendo un
          punto problem&aacute;tico para mucha gente que prefiere palabras,
          t&eacute;rminos o claves populares y que ya mucha otra gente conoce.
        </Paragraph>
        <Paragraph>
          Nuestra contrase&ntilde;a nunca deber&iacute;a poder aparecer en un
          diccionario de palabras o listado de contrase&ntilde;as frecuentes, ni
          deber&iacute;a ser un dato personal que sea p&uacute;blico y
          f&aacute;cilmente accesible. Algunos ejemplos de contrase&ntilde;as
          comunes que deber&iacute;as evitar son:
        </Paragraph>
        <UnorderedList>
          <ListItem>
            <strong>Conteo de n&uacute;meros</strong>: sin importar el orden de
            los n&uacute;meros, nunca deber&iacute;as simplemente introducir una
            cifra como contrase&ntilde;a, sin importar si se trata de
            n&uacute;meros pares, impares, primos, en orden inverso, contando
            hacia atr&aacute;s, etc. Evidentemente algo como 123456 es una muy
            mala contrase&ntilde;a, pero 654321 no es mucho mejor, como tampoco
            lo son 13579, 86420, y similares.
          </ListItem>
          <ListItem>
            <strong>Solo una o dos palabras</strong>: usar palabras o frases
            como &ldquo;<em>contrase&ntilde;a</em>&rdquo;, &ldquo;
            <em>password</em>
            &rdquo;, &ldquo;<em>clavedeseguridad</em>&rdquo;, &ldquo;
            <em>clavesecreta</em>&rdquo;, &ldquo;<em>passwordsegura</em>&rdquo;
            es definitivamente una p&eacute;sima idea y permitir&aacute; que
            alguien acceda a tu cuenta con suma facilidad. Una de las primeras
            cosas que se intentan durante un <em>hackeo</em> (o <em>crackeo</em>
            ) es probar todas y cada una de las miles de palabras existentes en
            el idioma espa&ntilde;ol o ingl&eacute;s, dado que probar todas las
            palabras del diccionario apenas requiere de unos segundos con un
            ordenador potente.
          </ListItem>
          <ListItem>
            <strong>Datos personales</strong>: fecha de nacimiento, edad,
            n&uacute;mero de identificaci&oacute;n, n&uacute;mero de patente,
            direcci&oacute;n del hogar, nombre de mascotas o familiares, color
            favorito, etc. pueden parecer datos personales dif&iacute;ciles de
            adivinar para un extra&ntilde;o que intenta con miles de claves al
            azar al hacer un ataque de fuerza bruta, pero cualquier persona que
            tenga su v&iacute;ctima en claro probar&aacute; todos y cada uno de
            los datos personales que encuentre a su disposici&oacute;n.{' '}
            <strong>
              Estos datos son personales pero p&uacute;blicos, cualquiera puede
              tener acceso a ellos.
            </strong>
          </ListItem>
        </UnorderedList>
        <Heading3>
          Asignar a cada cuenta una contrase&ntilde;as nueva y original, nunca
          repetirlas
        </Heading3>
        <Paragraph>
          Otra recomendaci&oacute;n bastante simple y de mucha obviedad, pero
          a&uacute;n as&iacute; muy importante dado que la gente a d&iacute;a de
          hoy repite mucho sus contrase&ntilde;as (o escoge algunas muy
          similares, con peque&ntilde;as variaciones) para que sea m&aacute;s
          f&aacute;cil introducirlas o recordarlas al momento de iniciar
          sesi&oacute;n, pero para esto existen los gestores de
          contrase&ntilde;as.
        </Paragraph>
        <Paragraph>
          Nunca es una buena idea repetir contrase&ntilde;as para simplificar su
          uso, dado que, si una de nuestras cuentas es parte de un ciberataque
          exitoso, entonces todas las dem&aacute;s cuentas que utilicen la misma
          contrase&ntilde;a pueden verse comprometidas f&aacute;cilmente.
        </Paragraph>
        <Paragraph>
          Cuando se hackea una base de datos con miles o millones de
          contrase&ntilde;as, algo muy com&uacute;n es vender la lista completa
          en alg&uacute;n mercado negro de internet, para que en futuros ataques
          se intente acceder a cuentas utilizando dichas contrase&ntilde;as
          previamente obtenidas de un hackeo exitoso.
        </Paragraph>
        <Paragraph>
          Que se filtre nuestra contrase&ntilde;a en tan solo un servicio puede
          significar que todas nuestras otras cuentas se vean comprometidas al
          mismo tiempo, simplemente por haber repetido la misma clave de
          seguridad en lugar de generar una clave &uacute;nica y aleatorio con
          un generador de contrase&ntilde;as online.
        </Paragraph>
        <Paragraph>
          Utilizar una herramienta de generaci&oacute;n de claves de seguridad
          no toma m&aacute;s que un par de segundos, por lo que no tiene sentido
          saltarse este paso y en su lugar simplemente reutilizar la misma
          contrase&ntilde;a una y otra vez, poniendo en riesgo m&uacute;ltiples
          cuentas al mismo tiempo.
        </Paragraph>
        <Heading3>
          Utilizar muchos caracteres: la longitud importa tanto como la
          complejidad, o incluso m&aacute;s
        </Heading3>
        <Paragraph>
          Ante un ataque de fuerza bruta (conocido en ingl&eacute;s como &ldquo;
          <em>brute force attack&rdquo;</em>), probar todas las combinaciones
          posibles con 3 o 4 caracteres resulta extremadamente f&aacute;cil y se
          puede hacer en segundos, pero cuando utilizamos{' '}
          <strong>16 caracteres o m&aacute;s</strong>, entonces un ataque donde
          se comprueben todas las combinaciones posibles se vuelve
          pr&aacute;cticamente imposible, dado que tomar&iacute;a millones de
          a&ntilde;os completarlos, incluso con ordenadores muy potentes.
        </Paragraph>
        <Paragraph>
          Es por eso que siempre deber&iacute;amos utilizar una
          contrase&ntilde;a que tenga 16 caracteres o m&aacute;s, siendo un
          l&iacute;mite m&aacute;ximo quiz&aacute; los 24 caracteres, dado que
          m&aacute;s all&aacute; de eso no tiene mucho sentido seguir agregando
          tiempo de c&oacute;mputo para descifrarla, ya estamos en el territorio
          de los miles de millones de a&ntilde;os, adem&aacute;s de que muchos
          sistemas no manejan muy bien las claves de seguridad de una longitud
          superior a 24 (mayormente sistemas desactualizados o antiguos).
        </Paragraph>
        <Heading3>
          Aun as&iacute;, la complejidad sigue siendo necesaria e importante
        </Heading3>
        <Paragraph>
          A pesar de que en los p&aacute;rrafos anteriores mencion&aacute;ramos
          que en la pr&aacute;ctica cualquier contrase&ntilde;a de 3 o 4
          caracteres se puede romper en unos pocos minutos sin importar su
          complejidad, esto no quiere decir que debamos escoger una
          contrase&ntilde;a absurdamente simple y f&aacute;cil de adivinar, dado
          que eso ser&aacute; un riesgo de seguridad.
        </Paragraph>
        <Paragraph>
          La complejidad es f&aacute;cil de lograr al utilizar el generador de
          contrase&ntilde;as aleatorios online que puedes encontrar en esta
          p&aacute;gina, dado que por defecto agregar&aacute; s&iacute;mbolos,
          may&uacute;sculas, min&uacute;sculas y n&uacute;meros en un orden
          completamente aleatorio.
        </Paragraph>
        <Paragraph>
          Y es que no solo tenemos letras y n&uacute;meros a nuestra
          disposici&oacute;n, sino que pr&aacute;cticamente todos los sistemas
          en la actualidad diferencian entre may&uacute;sculas y
          min&uacute;sculas, adem&aacute;s de aceptar caracteres especiales
          (como por ejemplo: &iexcl;!&iquest;?&euro;$%&amp;/\()=&mdash;+*).
        </Paragraph>
        <Heading2>Herramientas extras: gestores y 2FA</Heading2>
        <Paragraph>
          M&aacute;s all&aacute; de utilizar contrase&ntilde;as seguras
          obtenidas con este generador de claves de seguridad online,
          tambi&eacute;n es altamente recomendable hacer uso de dos tipos de
          herramientas o ayudas externas que nos mantendr&aacute;n m&aacute;s
          seguros y nos har&aacute;n la vida m&aacute;s f&aacute;cil: gestores
          de contrase&ntilde;a y factores de autenticaci&oacute;n adicionales.
        </Paragraph>
        <Paragraph>
          Un gestor de contrase&ntilde;a en la actualidad suele venir con muchas
          funcionalidades extras para intentar diferenciarse de la competencia,
          pero todos ellos cuentan con la capacidad fundamental y m&aacute;s
          importante, que es la de almacenar de forma segura todas nuestras
          claves, y permitirnos autocompletar formularios de forma r&aacute;pida
          y c&oacute;moda. Algunos de los gestores m&aacute;s populares hoy en
          d&iacute;a son:{' '}
          <Link
            color="orange.400"
            href="https://bitwarden.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Bitwarden
          </Link>
          , 1Password, LastPass, Dashlane, KeePass y el gestor de Google o el de
          Mozilla.
        </Paragraph>
        <Paragraph>
          Por su parte, el segundo factor de autenticaci&oacute;n, o 2FA, puede
          ser un n&uacute;mero m&oacute;vil con el que recibir SMS, una cuenta
          de WhatsApp o Telegram, un segundo email, entre muchas otras opciones;
          dependiendo de la plataforma que estemos utilizando tendremos varios a
          nuestra disposici&oacute;n.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
